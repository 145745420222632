import * as yup from "yup";

export const getSectionConfig = () => {
  return {
      "DBSettings": [
          ["DBType", "select", [["PostgreSql", 0], ["SQLite", 1], ["OCI(Oracle)", 2], ["MySQL", 3], ["ODBC(msSQL etc..)", 4]], yup.number('should be number').oneOf([0,1,2,3,4],'one of')],
          ["dbSimple", "checkbox", "", yup.boolean('should be boolean')],
          ["permanentConnections", "checkbox", "", yup.boolean('should be boolean')],
          ["maxPoolSize", "text", "", yup.number('should be number').integer('should be integer').required('required')],
          ["DBConnectionTimeout", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').min(100,'too short').required('required')],
          ["DatabaseName", "text", "", yup.string('should be string').required('required')],
          ["UserName", "text", "", yup.string('should be string')],
          ["HostName", "text", "", yup.string('should be string')],
          ["Port", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive')],
          ["Password", "text", "", yup.string('should be string')],
          ["Schema", "text", "", yup.string('should be string')],
          ["Options", "text", "", yup.string('should be string')],
          ["cacheDir", "text", "", yup.string('should be string').required('required')],
          ["SqlMaxLimit", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').max(5000,'too big').min(1,'too short').required('required')]
      ],
      "Utils": [
          ["useCachingTerms", "checkbox", "", yup.boolean('should be boolean')],
          ["termCacheType", "select", [["disk", 1], ["DB", 2]], yup.number('should be number').oneOf([1,2],'one of')],
          ["compressTermData", "checkbox", "", yup.boolean('should be boolean')],
          ["useThreadsInControllers", "checkbox", "", yup.boolean('should be boolean')],
          ["useThreadsForBenchmarks", "checkbox", "", yup.boolean('should be boolean')],
          ["maxThreadCount", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').min(4).required('required')],
          ["threadExpiryTimeout", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').min(1000).required('required')],
          ["enableTableLogs", "checkbox", "", yup.boolean('should be boolean')],
          ["maxLogDataLength", "text", "", yup.number('should be number').required('required')],
          ["excludeDataLog", "text", "", yup.string('should be string')],
          ["passwordRegex", "text", "", yup.string('should be string')],
          ["netUrl", "text", "", yup.string('should be string')],
          ["benchmarkTimeout", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').min(1000).required('required')],
          ["termsTimeout", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').min(1000).required('required')]
      ],
      "cors": [
          ["Access-Control-Allow-Origin", "text", "", yup.string('should be string')]
      ],
      "languageTool": [
          ["url", "text", "", yup.string('should be string')],
          ["apiKey", "text", "", yup.string('should be string')],
          ["username", "text", "", yup.string('should be string')]
      ],
      "listener": [
          ["host", "text", "", yup.string('should be string').required('required')],
          ["connectionTimeout", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').min(1000,'too short').required('required')],
          ["readTimeout", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').min(10,'too short').required('required')],
          ["maxThreads", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').max(100,'too big').required('required')],
          ["port", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive').required('required')],
          ["sslCertFile", "text", "", yup.string('should be string')],
          ["sslKeyFile", "text", "", yup.string('should be string')]
      ],
      "logging": [
          ["type", "select", [["console", "console"], ["file", "file"]], yup.string('should be string').oneOf(["console","file"]).required('required')],
          ["fileName", "text", "", yup.string('should be string')],
          ["maxBackups", "text", "", yup.number('should be number').integer('should be integer')],
          ["maxSize", "text", "", yup.number('should be number').integer('should be integer')],
          ["minLevel", "select", [["QtDebugMsg", 0], ["QtWarningMsg", 1], ["QtCriticalMsg", 2], ["QtFatalMsg", 3], ["QtInfoMsg", 4]], yup.number('should be number').oneOf([0,1,2,3,4],'one of')],
          ["msgFormat", "text", "", yup.string('should be string').required('required')],
          ["timestampFormat", "text", "", yup.string('should be string').required('required')],
          ["rotationTime", "text", "", yup.number('should be number').integer('should be integer').min(0,'too short')],
          ["logPassword", "text", "", yup.string('should be string')]
      ],
      "static": [
          ["enable", "checkbox", "", yup.boolean('should be boolean')],
          ["path", "text", "", yup.string('should be string')]
      ],
      "token": [
          ["maxLoginAttemps", "text", "", yup.number('should be number')],
          ["timeoutLoginAttemps", "text", "", yup.number('should be number')],
          ["secret", "text", "", yup.string('should be string')],
          ["tokenExpireMSec", "text", "", yup.number('should be number')]
      ],
      "treetagger": [
          ["cache_dir", "text", "", yup.string('should be string').required('required')],
          ["de", "text", "", yup.number('should be number').min(1,'too small').required('required')]
      ],
      "nn":[
          ["externalURL", "text", "", yup.string('should be string').required('required')],
          ["netTimeout", "text", "", yup.number('should be number').integer('should be integer').min(1000,'too short')],

          ["nnRunService", "checkbox", "", yup.boolean('should be boolean')],
          ["hostAddress", "text", "", yup.string('should be string')],
          ["port", "text", "", yup.number('should be number').integer('should be integer').positive('should be positive')],
          ["sslCertFile", "text", "", yup.string('should be string')],
          ["sslKeyFile", "text", "", yup.string('should be string')],
          ["modelDirPath", "text", "", yup.string('should be string')],
          ["useGPU", "checkbox", "", yup.boolean('should be boolean')],
          ["GpuDeviceId", "text", "", yup.number('should be number').integer('should be integer')],
          ["loggingType", "select", [["console", "console"], ["file", "file"]], yup.string('should be string').oneOf(["console","file"]).required('required')],
          ["loggingFileName", "text", "", yup.string('should be string')],
          ["loggingMaxBackups", "text", "", yup.number('should be number').integer('should be integer')],
          ["loggingMaxSize", "text", "", yup.number('should be number').integer('should be integer')],
          ["loggingMinLevel", "select", [["QtDebugMsg", 0], ["QtWarningMsg", 1], ["QtCriticalMsg", 2], ["QtFatalMsg", 3], ["QtInfoMsg", 4]], yup.number('should be number').oneOf([0,1,2,3,4],'one of')],
          ["loggingMsgFormat", "text", "", yup.string('should be string').required('required')],
          ["loggingTimestampFormat", "text", "", yup.string('should be string').required('required')]
      ],
      "redirect":[
          ["netTimeout", "text", "", yup.number('should be number').integer('should be integer').min(1000,'too short')],
          ["Summ", "checkbox", "", yup.boolean('should be boolean')],
          ["SummUsage", "checkbox", "", yup.boolean('should be boolean')],
          ["Assistant", "checkbox", "", yup.boolean('should be boolean')],
      ]
  }
}